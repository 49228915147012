import React from 'react'
import { DefaultLayout } from '../components/Layouts'
import Icon from '../components/Icon'
import PageTitle from '../components/PageTitle'
import Meta from '../components/Meta'

const SocialItem = ({ link = '/', icon, username, swapOpacity }) => (
  <a href={link} className="my-2 text-xl" rel="noopener noreferrer">
    <div className="flex -mx-2 items-center">
      <div className="px-2 w-10 text-2xl text-center">
        <Icon icon={icon} swapOpacity={swapOpacity} />
      </div>
      <div className="px-2">{username}</div>
    </div>
  </a>
)

const ContactPage = () => {
  return (
    <DefaultLayout>
      <Meta title="Contact" />
      <div className="max-w-xl mx-auto">
        <PageTitle>Get in Touch</PageTitle>
        <div className="row mb-10">
          <div className="col w-full md:w-1/2">
            <SocialItem
              link="https://www.github.com/blindingstars"
              username="blindingstars"
              icon={['fab', 'github']}
            />
            <SocialItem
              link="https://www.keybase.io/stephy"
              username="stephy"
              icon={['fab', 'keybase']}
            />
            <SocialItem
              link="https://www.codepen.io/blindingstars"
              username="blindingstars"
              icon={['fab', 'codepen']}
            />
            <SocialItem
              link="https://www.linkedin.com/in/stephymiehle"
              username="stephymiehle"
              icon={['fab', 'linkedin']}
            />
          </div>
          <div className="col w-full md:w-1/2">
            <SocialItem
              link="mailto:stephy@northxsouth.co"
              username="stephy@northxsouth.co"
              icon={['fad', 'envelope']}
            />
            <SocialItem
              link="https://www.instagram.com/stephymiehle"
              username="stephymiehle"
              icon={['fab', 'instagram']}
            />
            <SocialItem
              link="https://www.facebook.com/stephymiehle"
              username="stephymiehle"
              icon={['fab', 'facebook']}
            />
            <SocialItem
              link="https://www.twitter.com/stephymiehle"
              username="stephymiehle"
              icon={['fab', 'twitter']}
            />
          </div>
        </div>
        <div className="my-5 mx-auto bg-success-50 p-8 text-center md:text-left rounded-lg">
          <h3 className="text-success-600 font-semibold uppercase text-2xl mb-4">
            Secure Messaging
          </h3>
          <div className="row flex-wrap">
            <div className="col w-full md:w-auto flex-grow">
              <p>
                Want to message me securely? Know&nbsp;PGP?
                <br />
                My key can be verified at{' '}
                <a
                  href="https://keybase.io/stephy"
                  className="text-success hover:text-success-600"
                >
                  keybase.io/stephy
                </a>
                .
              </p>
            </div>
            <div className="col w-auto mt-6 md:mt-0 mx-auto">
              <p>
                <a href="/pgp" className="btn btn-success">
                  Get Public Key
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default ContactPage
