import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faGithub,
  faLinkedin,
  faKeybase,
  faCodepen,
} from '@fortawesome/free-brands-svg-icons'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faInstagram,
  faFacebook,
  faTwitter,
  faHeart,
  faHeartRegular,
  faGithub,
  faLinkedin,
  faKeybase,
  faEnvelope,
  faCodepen
)

const Icon = ({ ...props }) => <FontAwesomeIcon {...props} />

export default Icon
